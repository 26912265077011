import { configureTranslations, t } from 'i18n';
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import App from './container/App';
import {
    ApplyButton,
    CancelButton,
    configureAfterSave,
    configureApi,
    configureBasename,
    configureNotification,
    configurePermission,
    RightDivider,
    spiderModule,
    ViewStore
} from '@code-yellow/spider';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { theme } from 'styles';

import i18next from 'i18next';
// use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { Router } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import * as Sentry from '@sentry/react';
import configureSentry, { setUserSentry } from './sentry';
import { PUBLIC_URL, configOverride } from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureCurrentUser } from 'helpers/currentUser';
import { Modal } from 'semantic-ui-react';
import 'moment-duration-format';
import { api } from 'store/Base';
import { User } from 'store/User';
import { configureTranslation as configureDayCYTranslation } from 'daycy';
import { createBrowserHistory } from 'history';
import { configureModules } from '_iae/module/repository';
import { systemModule } from './_iae/system'

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';
import '@material-design-icons/font';
import 'style/material-design-icons/icons.css';

configureApi(api);

const moduleRepository = configureModules([
    systemModule,
    spiderModule
])
configureTranslations(moduleRepository);


configureDayCYTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

class SpecialViewStore extends ViewStore {
    @action
    handleBootstrap(res) {
        configOverride(res);
        if (res.language) {
            i18next.changeLanguage(res.language);
        }
        return super.handleBootstrap(res);
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)
    }
};


const viewStore = new SpecialViewStore({
	api,
	user: new User(null, {
        relations: ['groups.permissions']
    }),
	socketUrl: `${PUBLIC_URL || ''}/ws/`
});

/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;

export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({ goBack: false, createUrl: '/add' });
configureCurrentUser(viewStore);

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertMessage = '';
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        this.alertConfirm(true);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                <Modal size="tiny" open={this.showAlert} centered={false}>
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <p>{this.alertMessage}</p>
                        <p>{t('form.confirmQuestion')}</p>
                    </Modal.Content>
                    <Modal.Actions style={{ display: 'flex' }}>
                        <CancelButton negative onClick={this.cancel} />
                        <RightDivider />
                        <ApplyButton positive onClick={this.confirm} content={t('form.continueButton')} />
                    </Modal.Actions>
                </Modal>
                <ReCyCleTheme theme={theme}>
                    <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                        this.showAlert = true;
                        this.alertConfirm = confirm;

                        if (typeof message === 'object') {
                            this.alertMessage = message.message;
                            this.alertOnApply = message.onApply;
                            this.alertOnCancel = message.onCancel;
                        } else {
                            this.alertMessage = message;
                            this.alertOnApply = null;
                            this.alertOnCancel = null;
                        }

                    }}>
                        <App moduleRepository={moduleRepository} store={viewStore} />
                    </Router>
                </ReCyCleTheme>
            </React.Fragment>
        </Sentry.ErrorBoundary>
        );
    }
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<Root />, container);
